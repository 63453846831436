import actions from "../Constants/actions";

const getInitialState = () => {
    return {
        inited: false,
        isLoadingReasons: false,
        reasons: [],
        selectedReason: null,
        isFinishedFlow: false,
        isCanceling: false,
        eligibility: {
            is_loading: false,
            is_eligible: false,
            coupon_data: {
                discount: null,
                type: null
            }
        }
    }
}

const initialState = getInitialState();

export default (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case actions.CANCEL.SET_LOADING_REASONS:
            return {
                ...state,
                isLoadingReasons: payload
            }
        case actions.CANCEL.UPDATE_REASONS:
            return {
                ...state,
                reasons: payload,
                isLoadingReasons: false
            }
        case actions.CANCEL.SET_SELECTED_REASON:
            return {
                ...state,
                selectedReason: payload
            }
        case actions.CANCEL.SET_CANCELING_APPOINTMENT:
            return {
                ...state,
                isCanceling: payload
            }
        case actions.CANCEL.UPDATE_CANCELATION_STATUS:
            return {
                ...state,
                isCanceling: false
            }
        case actions.CANCEL.SET_LOADING_ELIGIBILITY:
            return {
                ...state,
                eligibility: {
                    ...state.eligibility,
                    is_loading: payload
                }
            }
        case actions.CANCEL.SET_ELIGIBILITY_DATA:            
            return {
                ...state,
                inited: true,
                eligibility: {
                    ...state.eligibility,
                    is_loading: false,
                    ...payload
                }
            }
        case actions.CANCEL.SET_USER_FREE_TEXT:
            return {
                ...state,
                reasonFreeText: payload.text
            }
        case actions.CANCEL.CLEAR_CANCELLATION_DATA:
            return {
                ...getInitialState(),
                reasons: state.reasons,
            }
        case actions.CANCEL.SET_FINISHED_FLOW:
            return {
                ...state,
                isFinishedFlow: true
            }
        default:
            return state;
    }
}