export default {
	ROOT: '/',
	MY_SERVICES: '/my-services',
	APPOINTMENT_DETAILS: '/my-services/:uuid',
	DETAILS: '/details',
	TECHNICIAN_PATH: '/my-services/:id/technician',
	RESCHEDULE: '/reschedule',
	STF: '/stf',
	TIMES: '/times',
	CANCEL: '/cancel',
	CONFIRM: '/confirm',
	CONFIRMED: '/confirmed',
	MEMBERSHIP: '/membership',
	MEMBERSHIP_DEVICES: '/membership/devices',
	SETTINGS: '/settings',
	SETTINGS_ABOUT: '/settings/about',
	SETTINGS_PROFILE: '/settings/profile',
	SETTINGS_CONTACT: '/settings/contact',
	LOGIN: '/login',
	VERIFICATION: '/verification',
	SIGN_UP: '/sign-up',
	CHAT_CLAIM: '/chat-claim',

	PROFILE: '/profile',
	MEMBERSHIP_EDIT:'/edit',
    PAYMENT_EDIT: '/payment',
	DEVICES:'/devices',
	SETTINGS_PAYMENT:'/settings/payment',
    SETTINGS_MEMBERSHIP: '/settings/membership',

	CONFIRM_CANCELLATION: '/cancel/confirm',
	CANCELLATION_OFFER_ACCEPTED: '/cancel/offer-accepted',
	CANCELLATION_APPROVED: '/cancel/approved',
	CANCELLATION_RESCHEDULE: '/cancel/reschedule',
};
