import actionConstants from '../Constants/actions.ts';
import _ from 'lodash';

const initialState = {
	show: false,
	message: '',
	buttonText: ''
};

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
	case (actionConstants.SNACKBAR.SHOW_SNACKBAR):
		newState = _.cloneDeep(state);
		newState.show = true;
		newState = Object.assign(newState, action.payload);
		return newState;
	case (actionConstants.SNACKBAR.HIDE_SNACKBAR):
		newState = _.cloneDeep(state);
		newState.show = false;
		return newState;
	default:
	}
	return state;
};
