import actionConstants from '../Constants/actions.ts';
import _ from 'lodash';

const initialState = {
	iframe_link: '',
	is_app_mode: false,
	show_return_from_sale: false,
	is_menu_open: false,
	error: null
};

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
	case (actionConstants.CONFIG.UPDATE_IFRAME_LINK):
		newState = _.cloneDeep(state);
		newState.iframe_link = action.payload;
		return newState;
	case (actionConstants.CONFIG.UPDATE_APP_MODE):
		newState = _.cloneDeep(state);
		newState.is_app_mode = action.payload;
		return newState;
	case (actionConstants.CONFIG.UPDATE_RETURN_FROM_SALE_FLAG):
		newState = _.cloneDeep(state);
		newState.show_return_from_sale = action.payload;
		return newState;
	case (actionConstants.CONFIG.TOGGLE_MENU_MODE):
		newState = _.cloneDeep(state);
		newState.is_menu_open = !state.is_menu_open
		return newState;
	case (actionConstants.CONFIG.SET_ERROR):
		return {
			...state,
			error: action.payload.errorMessage
		}
	default:
	}
	return state;
};
