import { EDevicesIds } from 'src/Models/Device';
import bot1 from '../Images/claimChat/avatar1.png';
import bot2 from '../Images/claimChat/avatar2.png';
import bot3 from '../Images/claimChat/avatar3.png';
import bot4 from '../Images/claimChat/avatar4.png';
import { EIssueDishwasherSelection, EIssueDryerSelection, EIssueRefirigeratorSelection, EIssueWasherSelection } from './messages';

export const DEFAULT_CATEGORY = 'Other';
export const ADD_ONS_CATEGORY = 'Add-ons';

export const DEFAULT_PLAN_ID = 10;

export const PRICE_VALUE_TYPES_IDS = {
    PERCENTAGES: 1,
    USD: 2
}

export const botsImages = [
    bot1,
    bot2,
    bot3,
    bot4,
]

export const devicesIssuesMap = {
    [EDevicesIds.FRIDGE]: {
        selections: [
            {
                id: EIssueRefirigeratorSelection.NOT_COOLING,
                text: "Not cooling"
            },
            {
                id: EIssueRefirigeratorSelection.NOISY,
                text: "Noisy"
            },
            {
                id: EIssueRefirigeratorSelection.LEAKING,
                text: "Leaking"
            },
            {
                id: EIssueRefirigeratorSelection.ICE_MAKER_NO_WORKING,
                text: "Ice maker no working"
            },
            {
                id: EIssueRefirigeratorSelection.OTHER,
                text: "Other"
            },
        ],
        otherOptionId: EIssueRefirigeratorSelection.OTHER
    },
    [EDevicesIds.DISHWASHER]: {
        selections: [
            {
                id: EIssueDishwasherSelection.LEAKING,
                text: "Leaking"
            },
            {
                id: EIssueDishwasherSelection.NOT_DRAINING,
                text: "Not draining"
            },
            {
                id: EIssueDishwasherSelection.ODOR,
                text: "Odor"
            },
            {
                id: EIssueDishwasherSelection.NOISY,
                text: "Noisy"
            },
            {
                id: EIssueDishwasherSelection.OTHER,
                text: "Other"
            },
        ],
        otherOptionId: EIssueDishwasherSelection.OTHER
    },
    [EDevicesIds.WASHER]: {
        selections: [
            {
                id: EIssueWasherSelection.LEAKING,
                text: "Leaking"
            },
            {
                id: EIssueWasherSelection.ODOR,
                text: "Odor"
            },
            {
                id: EIssueWasherSelection.NO_POWER,
                text: "No power"
            },
            {
                id: EIssueWasherSelection.NOT_DRAINING,
                text: "Not draining"
            },
            {
                id: EIssueWasherSelection.DRUM_ISSUES,
                text: "Drum issues"
            },
            {
                id: EIssueWasherSelection.OTHER,
                text: "Other"
            },
        ],
        otherOptionId: EIssueWasherSelection.OTHER
    },
    [EDevicesIds.DRYER]: {
        selections: [
            {
                id: EIssueDryerSelection.NOT_HEATING,
                text: "Not heating"
            },
            {
                id: EIssueDryerSelection.BURNING_SMELL,
                text: "Burning smell"
            },
            {
                id: EIssueDryerSelection.DRUM_ISSUE,
                text: "Drum issue"
            },
            {
                id: EIssueDryerSelection.NO_POWER,
                text: "No power"
            },
            {
                id: EIssueDryerSelection.OTHER,
                text: "Other"
            },
        ],
        otherOptionId: EIssueDryerSelection.OTHER
    }
}

export const servicesIds = {
    PULS_DISCOUNT: 1456,
    SUPPORE_FEE: 1451,
    COUPON: 16
}

export const devicesIds = {
    BAF: 358,
    HISENSE: [372, 373, 374, 375, 376],
    HOME_CHECK_UP: 343,
    HVAC: 377
}

export const abTestsNames = {
    HOURLY_PRICES: 'Handyman hourly price'
}

export const appointmentStatusesIds = {
    NEW: 1,
    SUPPORTED: 2,
    ASSIGNED: 3,
    ON_MY_WAY: 4,
    IN_PROGRESS: 6,
    DONE: 7,
    CANCELED: 8,
}

export const pastAppointmentStatusesIds = [appointmentStatusesIds.DONE, appointmentStatusesIds.CANCELED]

export const verticalsIds = {
    PHONE: 1,
    TV: 2,
    SMART_HOME: 3,
    HELPER: 4,
    ANTENNAS: 5,
    COMPUTER: 6,
    GARAGE: 7,
    APR: 8,
    PLUMBUNG: 9,
    HANDYMAN: 10,
    DISINFECTION: 11
}

export const funnelsIds = {
    HANDYMAN: 18,
    TV: 4,
    APR: 15,
    PHONE: 5,
    SMART_HOME: 3,
    PLUMBUNG: 17,
    GARAGE: 14,
    MDR: 5,
    SECURITY_SYSTEMS: 2,
    DISINFECTION: 20
}

export const paymentMethods = {
    CREDIT_CARD: 'credit_card',
    PAYPAL: 'paypal_account'
}

export const MILESTONES_IDS = {
    HANDYMAN_CREDIT: 1,
    FREE_CHECK_UP: 2,
    HVAC_INSPECTION: 3,
}

export const appointmentStatusesTitles = {
    [appointmentStatusesIds.SUPPORTED]: 'Requested',
    [appointmentStatusesIds.ASSIGNED]: 'Tech Assigned'
}

export const APPT_CANCELLATION_FLOWS = {
    APR: 'APR',
    DEFAULT: 'DEFAULT'
}

export const CANCELLATION_FLOW_BY_VERTICAL_ID = {
    [verticalsIds.APR]: APPT_CANCELLATION_FLOWS.APR
}

export const DISCOUNT_VALUE_TYPES = {
    PERCENTAGE: 'PERCENTAGE',
    USD: 'USD'
}