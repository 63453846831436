import moment from 'moment'

const TIME_ZERO_OBJECT = {hour:0,minute:0,second:0,millisecond:0}

export default {
	price: (price = 0) => {
		const sign = price < 0 ? '-' : '';
		const absPrice = Math.abs(price);
		return `${sign}$${absPrice.toFixed(2)}`;
	},
	address: (address) => {
		const parts = [address.street, address.street_number, address.city];
		return parts.filter(a => a).join(', ');
	},
	phone: (str) => {
		const match = str.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) return `(${match[1]}) ${match[2]} - ${match[3]}`;
		return str;
	},
	timeSlot: (time, duration = 60) => {
		let startTime = moment().utc().set(TIME_ZERO_OBJECT).add(time, 'minutes').format('ha')
		let endTime = moment().utc().set(TIME_ZERO_OBJECT).add(time + (duration || 60), 'minutes').format('ha')
		return `${startTime} - ${endTime}`
	}
};
