import actionConstants from '../Constants/actions';

export interface RescheduleReducer {
	isValidForReschedule: Boolean
	isSubmittingReschedule: Boolean,
	reschedule_uuid: string | null,
	rescheduleAppointment: any
}

const initialState: RescheduleReducer = {
	isValidForReschedule: false,
	isSubmittingReschedule: false,
	reschedule_uuid: null,
	rescheduleAppointment: null
};

export default (state = initialState, action: any) => {
	const { type, payload } = action;
	switch (type) {
	case actionConstants.RESCHEDULE.SET_RESCHEDULE_VALIDATION:
		return {
			...state,
			isValidForReschedule: payload
		}
	case actionConstants.RESCHEDULE.SET_SUBMIT_RESCHEDULE:
		const { isSubmittingReschedule } = payload;
		return {
			...state,
			isSubmittingReschedule
		}
	case actionConstants.RESCHEDULE.SET_RESCHEDULE_APPOINTMENT_UUID:
		const { appointment_uuid } = payload;
		return {
			...state,
			reschedule_uuid: appointment_uuid,
			isSubmittingReschedule: false
		}
	case actionConstants.RESCHEDULE.UPDATE_RESCHEDULE_APPOINTMENT:
		const { rescheduleAppointment } = payload;
		return {
			...state,
			rescheduleAppointment: rescheduleAppointment || null
		}
	default:
		return state;
	}
};
