import { Device } from 'src/Models/Device.js';
import { MessageBlock } from 'src/Models/MessageBlock.js';
export default {
    validatePickedDate: (result: any) => {
        return result.is_succeeded;
    },
    getSelectedDevice: (devices:Device[], id:number) => {
        return devices.find((device: Device) => device.device_id === id);
    },
    getMessageIndex: (messages: MessageBlock[], id: number) => {
        return messages.findIndex((message) => message.id === id);
    }
}