import actionConstants from '../Constants/actions.ts';
import _ from 'lodash';

const initialState = {
	list: [],
	isLoadingDetails: false,
	isLoadingAppointments: false,
	appointmentDetails: null,
	isSendingInvoice: false,
};

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
	case (actionConstants.USER.UPDATE_USER_DATA):
		if(!action.payload.appointments) return state;
		newState = _.cloneDeep(state);
		newState.list = action.payload.appointments;
		newState.isLoadingAppointments = false
		return newState;
	case (actionConstants.APPOINTMENTS.UPDATE_LOADING_APPOINTMENT_DETAILS):
		newState = _.cloneDeep(state);
		newState.isLoadingDetails = action.payload;
		if (newState.isLoadingDetails) { // loading new appointment clear current one
			newState.appointmentDetails = null;
		}
		return newState;
	case (actionConstants.APPOINTMENTS.GET_APPOINTMENT_DETAILS):
		newState = _.cloneDeep(state);
		newState.isLoadingDetails = false;
		newState.appointmentDetails = action.payload;
		return newState;
	case (actionConstants.APPOINTMENTS.UPDATE_LOADING_APPOINTMENTS):
		newState = _.cloneDeep(state);
		newState.isLoadingAppointments = action.payload;
		return newState;
	case (actionConstants.APPOINTMENTS.UPDATE_SENDING_INVOICE_INDICATOR):
		newState = _.cloneDeep(state);
		newState.isSendingInvoice = action.payload;
		return newState;
	default:
	}
	return state;
};
