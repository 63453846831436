import utils from './utils';
import moment from 'moment';
import config from '../config';
const EXP_TIME = 15;

export default {
	CookieHandler: {
		get: (name) => {
			return utils.getCookieByName(name);
		},
		set: (name, value, expiry = false, options = {}) => {
			let expires;
			if(!options.sessionCookie) {
				expires = expiry || moment().add(EXP_TIME, 'day').toDate();
				expires = new Date(expires).toUTCString();
			}
			let cookieAddition = '';
			if (window.location.hostname !== 'localhost') {
				cookieAddition = `; ${options.sessionCookie ? '' : `expires=${expires}`}; path=/; SameSite=None ; Secure ; domain=${config.domain().replace('https://', '.')}`;
			}
			const cookie = `${name}=${value}` + cookieAddition;
			// "username=John Doe; expires=Thu, 18 Dec 2020 12:00:00 UTC; path=/;"
			document.cookie = cookie;
		}
	}
};
