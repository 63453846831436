export enum EBlockTypes{
    USER, BOT
}

export enum EBlockContext{
    FILE_A_CLAIM,
    FILE_A_CLAIM_ANSWER,
    ENTER_ISSUE_START_DATE,
    START_DATE_VALID,
    START_DATE_INVALID,
    START_DATE_ERROR,
    MANUFACTURER_WARRANTY,
    CREATE_APPOINTMENT_SELECTION,
    IN_MANUFACTURER_WARRANTY_ANSWER,
    NO_MANUFACTURER_WARRANTY_ANSWER,
    UNSURE_MANUFACTURER_WARRANTY_ANSWER,
    IN_AND_UNSURE_MANUFACTURER_SELECTION,
    YEARS_SELECTION,
    FRIDGE_BUILT_IN_QUESTION,
    FRIDGE_BUILT_IN_SELECTION,
    DRYER_WASHER_PLACE_QUESTION,
    DRYER_WASHER_PLACE_SELECTION,
    DRYER_TYPE_QUESTION,
    DRYER_TYPE_SELECTION,
    DEVICE_BRAND_QUESTION,
    DEVICE_BRAND_INPUT,
    DEVICE_BRAND_INPUT_ANSWER,
    DEVICE_MODEL_SELECTION,
    DEVICE_MODEL_SELECTION_ANSWER_YES,
    DEVICE_MODEL_SELECTION_ANSWER_NO,
    DEVICE_MODEL_INPUT,
    SERIAL_NUMBER_QUESTION,
    SERIAL_NUMBER_SELECTION,
    SERIAL_NUMBER_ANSWER_YES,
    SERIAL_NUMBER_ANSWER_NO,
    SERIAL_NUMBER_INPUT,
    SELECT_ISSUE_QUESTION,
    SELECT_ISSUE_SELECTION,
    ISSUE_DETAILS_QUESTION,
    ISSUE_DETAILS_INPUT,
    PICK_ARRIVAL_TIME_QUESTION,
    PICK_ARRIVAL_TIME_DATE_PICKER,
    USER_DETAILS,
    USER_DETAILS_SELECTION,
    USER_DETAILS_CORRECT_ANSWER,
    USER_DETAILS_INCORRECT_ANSWER,
    PAY_NOW_QUESTION,
    PAY_NOW_PICKER,
    OPEN_APPOINTMENT_CREATION,
    PAYMENT_SUCCESS,
    END_CLAIM_CHAT,
    CLAIM_CREATION_ERROR,
    PAYMENT_METHOD_SELECTION,
    DEFAULT_PAYMENT_METHOD_ERROR
}

export enum EEditTypes {
    NON_EDITABLE,
    EDIT_AND_CONTINE,
    EDIT_AND_ERASE
}

export enum ESelectionTypes {
    DATE_PICKER,
    TIME_SLOTS_PICKER,
    BRAND_PICKER,
    INPUT,
    REGULAR,
    PAYMENT
}

export enum EYesNoSelection {
    YES,
    NO
}

export enum EManufacturerSelection {
    YES,
    NO,
    UNSURE
}

export enum EInManufactureSelection {
    CHECK_WARRANTY,
    PULS_REPAIR,
    CONTINUE_CLAIM
}

export enum EYearsSelection {
    YEARS_1_TO_3,
    YEARS_4_TO_6,
    YEARS_7_TO_10,
    MORE_THAN_10_YEARS
}

export enum EYearsSelectionWholeHome {
    YEARS_1_TO_3,
    YEARS_4_12,
    MORE_THAN_12_YEARS
}

export enum EIssueRefirigeratorSelection {
    NOT_COOLING,
    NOISY,
    LEAKING,
    ICE_MAKER_NO_WORKING,
    OTHER
}

export enum EIssueDishwasherSelection {
    LEAKING,
    NOT_DRAINING,
    ODOR,
    NOISY,
    OTHER
}

export enum EIssueWasherSelection {
    LEAKING,
    ODOR,
    NO_POWER,
    NOT_DRAINING,
    DRUM_ISSUES,
    OTHER
}

export enum EIssueDryerSelection {
    NOT_HEATING,
    BURNING_SMELL,
    DRUM_ISSUE,
    NO_POWER,
    OTHER
}

export enum EDryerWasherPlaced {
    STACKED,
    SIDE_BY_SIDE
}

export enum EDryerTypes {
    ELECTRIC,
    GAS,
    NOT_SURE
}