import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from './Reducers';

const middleware = [];
middleware.push(thunk);

const store = createStore(
	reducers, composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
