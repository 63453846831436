import actionConstants from '../Constants/actions.ts';
import _ from 'lodash';

const initialState = {
	uuid: '',
	isSendingEmailVerification: false,
	isVerifing: false,
	verificationCode: {
		1: '',
		2: '',
		3: '',
		4: '',
		5: '',
		6: ''
	}
};

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
	case (actionConstants.VERIFICATION.CHNAGE_SEND_EMAIL_VERIFICATION_INDICATOR):
		newState = _.cloneDeep(state);
		newState.isSendingEmailVerification = action.payload;
		return newState;
	case (actionConstants.VERIFICATION.UPDATE_VERIFICATION_DATA):
		newState = _.cloneDeep(state);
		newState.isSendingEmailVerification = false;
		newState.uuid = action.payload.uuid;
		return newState;
	case (actionConstants.VERIFICATION.CHNAGE_VERIFICATION_CODE_NUMBER):
		newState = _.cloneDeep(state);
		newState.verificationCode[action.payload.item] = action.payload.value;
		return newState;
	case (actionConstants.VERIFICATION.UPDATE_VERIFICATION_INDICATOR):
		newState = _.cloneDeep(state);
		newState.isVerifing = action.payload;
		return newState;
	case (actionConstants.VERIFICATION.UPDATE_AUTHENTICATION_RESULT_DATA):
		newState = _.cloneDeep(state);
		newState.isVerifing = false;
		return newState;
	default:
	}
	return state;
};
