import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './App/App.scss';
import './App/fonts.scss';
import './App/index.css';
import utils from './Services/utils';

const renderApp = (App) => {
	ReactDOM.render(
		<App />,
		document.getElementById('root')
	);
};


if (!!utils.getCookieByName('test_tailwind')) {
	renderApp(() => <div className='text-center bg-slate-600'>kuku</div>);
} else if (window.location.pathname.indexOf('/admin-claim-chat') !== -1) {
	import('./App/ChatApp.js').then(comp => {
		renderApp(comp.default);
	});
} else {
	import('./App/PortalApp.js').then(comp => {
		renderApp(comp.default);
	});
}

/*
	<React.StrictMode>
		<App />
	</React.StrictMode>,
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
