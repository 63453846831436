import { combineReducers } from 'redux';
import user from './user.js';
import config from './config.js';
import snackbar from './snackbar.js';
import verification from './verification.js';
import appointments from './appointments.js';
import content from './content.js';
import claim from './claim';
import cancel from './cancel';
import utils from './utils';
import reschedule from './reschedule';
import { timeReducer as time } from '@puls/puls-general-utils';
const rootReducer = combineReducers({
	config, user, verification, snackbar, appointments, content, claim, cancel, utils, time, reschedule
});

export default rootReducer;
