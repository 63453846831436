import { EEditTypes } from 'src/Constants/messages';
import { MessageBlock } from './../Models/MessageBlock';
import actionConstants from '../Constants/actions';
import cloneDepp from 'lodash/cloneDeep';
import claimService from '../Services/claim-chat';

export interface ClaimReducer{
    uuid: string,
    nonce: string,
    admin_id: number,
    messages: MessageBlock[],
    claim_device_id: number,
    subscription_device_id: number,
    chat_is_over: boolean,
    valid_for_claim: boolean,
    claim_validation_message: string,
    invalid_type: number
    source: string,
    isLoading: boolean,
    errorMessage: string
}

const initialState: ClaimReducer = {
    uuid: '',
    nonce: '',
    messages: [],
    claim_device_id: 0,
    subscription_device_id: 0,
    chat_is_over: false,
    valid_for_claim: false,
    claim_validation_message: '',
    invalid_type: 0,
    admin_id: 0,
    source: 'consumer_portal',
    isLoading: false,
    errorMessage: ''
}

let messagesLogic: any = null;

export default (state: ClaimReducer = initialState, action: any) => {
    switch (action.type) {
        case actionConstants.CLAIMS.UPDATED_MESSAGES: 
            messagesLogic = action.payload;
            return state;
        case actionConstants.CLAIMS.INSERT_BLOCK_OBJECT:
            return {
                ...state,
                messages: [ ...state.messages, action.payload ]
            };
        case actionConstants.CLAIMS.UPDATE_SELECT_ROW:
            const rowIndexToChange = claimService.getMessageIndex(state.messages, action.payload.id);
            const updatedMessages = [...state.messages];
            updatedMessages[rowIndexToChange] = {...updatedMessages[rowIndexToChange], selected: action.payload.selectedData, is_in_edit_mode: !!action.payload.options.is_in_edit_mode};
            if(messagesLogic[action.payload.id].generateRows) {
                const updatedRows = messagesLogic[action.payload.id].generateRows(action.payload.selectedData);
                updatedMessages[rowIndexToChange] = {...updatedMessages[rowIndexToChange], rows: updatedRows};
            }
            return {
                ...state,
                messages: updatedMessages,
                uuid: action.payload.options.uuid_from_be,
                nonce: action.payload.options.nonce
            }
        case actionConstants.CLAIMS.UPDATE_CLAIMABLE_DEVICE:
            return {
                ...state,
                ...action.payload
            };
        case actionConstants.CLAIMS.START_OVER_CLAIM:
            // here we only initializing the state..
            // the deviceId will stay the same
            return {
                ...state,
                messages: [],
                chat_is_over: false

            };
        case actionConstants.CLAIMS.CLEAR_CLAIM_CHAT:
            return cloneDepp(initialState);
        case actionConstants.CLAIMS.CLEAR_TO_INDEX:
            const rowIndexToClear = claimService.getMessageIndex(state.messages, action.payload.id);
            const messagesClone = [...state.messages];
            const clearedMessages = messagesClone.slice(0, rowIndexToClear + 1);
            return {
                ...state,
                messages: clearedMessages
            }
        case actionConstants.CLAIMS.UPDATE_CLAIMABLE:
            return {
                ...state,
                valid_for_claim: action.payload.valid_for_claim,
                claim_validation_message: action.payload.message,
                invalid_type: action.payload.type
            }
        case actionConstants.CLAIMS.CREATE_CLAIM:
            return {
                ...state,
                isLoading: true
            }
        case actionConstants.CLAIMS.END_CLAIM_CHAT:
        case actionConstants.CLAIMS.CREATE_CLAIM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                chat_is_over: true,
                messages : cloneDepp(state.messages).map((message) => {
                    message.edit_type = EEditTypes.NON_EDITABLE;
                    return message;
                })
            }
        case actionConstants.CLAIMS.CREATE_CLAIM_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload
            }
        case actionConstants.CLAIMS.UPDATE_ADMIN_DATA:
            return {
                ...state,
                admin_id: action.payload,
                source: 'admin'
            }
        case actionConstants.CLAIMS.SET_ERROR_MESSAGE: 
            return {
                ...state,
                errorMessage: action.payload
            } 
        case actionConstants.CLAIMS.SET_IS_LOADING: 
            return { 
                ...state, 
                isLoading: action.payload
            }
        case actionConstants.CLAIMS.SET_CLAIM_UUID: 
            return {
                ...state, 
                uuid: action.payload
            }
    }
    return state;
}