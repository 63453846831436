import store from '../store.js';
import RoutesConstants from '../Constants/routes.js';
import {REASONS_IDS, SELECTION_OPTIONS, ESelectionTypes, routePatterns} from '../Constants/appt-cancellation';
import { matchPath } from 'react-router-dom';
import config from '../config.js'
import axios from 'axios'

export default {
	nextRoute: async (selection: any = {}) => { 
		return new Promise(async (resolve, reject) => {
			try { 
				const appState = store.getState();
				const pathname = window.location.pathname;
				
				let path;
				if(_matchPath(pathname, routePatterns.CANCEL, {exact: true})){
					path = RoutesConstants.CANCEL;
				} else if (_matchPath(pathname, routePatterns.CONFIRM_CANCELLATION, {exact: true})){
					path = RoutesConstants.CONFIRM_CANCELLATION;
				} else if (_matchPath(pathname, routePatterns.RESCHEDULE, {exact: true})) {
					path = RoutesConstants.RESCHEDULE
				} else if (_matchPath(pathname, routePatterns.CANCEL_RESCHEDULE, {exact: true})) {
					path = RoutesConstants.CANCEL + RoutesConstants.RESCHEDULE
				} else if(_matchPath(pathname, routePatterns.RESCHEDULE_TIMES, {exact: true})) {
					path = RoutesConstants.TIMES	
				} else if(_matchPath(pathname, routePatterns.CANCEL_RESCHEDULE_TIMES, {exact: true})) {
					path = RoutesConstants.CANCEL + RoutesConstants.TIMES
				} else if(_matchPath(pathname, routePatterns.RESCHEDULE_CONFIRM, {exact: true})) {
					path = RoutesConstants.RESCHEDULE + RoutesConstants.CONFIRM	
				} else if(_matchPath(pathname, routePatterns.CANCEL_RESCHEDULE_CONFIRM, {exact: true})) {
					path = RoutesConstants.CANCEL + RoutesConstants.RESCHEDULE + RoutesConstants.CONFIRM
				}

				if(path) { 
					let nextPath = await NEXT_ROUTE[path]({appState, selection});
					if(nextPath) return resolve(`${RoutesConstants.APPOINTMENT_DETAILS.replace(/:uuid/, appState.appointments.appointmentDetails.uuid)}${nextPath}`)
				}
				return reject('Cant get next route')
			} catch (e) {
				reject(e);
			}
		})
	},
	isEligible: (uuid: string) => {
        const url = `${config.api()}/appointment/eligible-for-cancellation-save/${uuid}`;
        return axios({
            method: 'GET',
            url
        })
    }
}

const NEXT_ROUTE = { 
	[RoutesConstants.CANCEL]: ({appState, selection}: any) => {
		const { eligibility, selectedReason } = appState.cancel;
		let reasonId = selectedReason ? selectedReason.id : null;
		if(!reasonId) return;
		if(eligibility.is_eligible) {
			switch(reasonId) {
				case REASONS_IDS.ISSUE_RESOLVED: 
				case REASONS_IDS.ANOTHER_COMPANY: 
					return RoutesConstants.CANCELLATION_APPROVED
				default: return RoutesConstants.CONFIRM_CANCELLATION;
			}
		}
		return RoutesConstants.CONFIRM_CANCELLATION;
	},
	[RoutesConstants.CONFIRM_CANCELLATION]: ({appState, selection}: any) => {
		const { selectedReason } = appState.cancel;
		let reasonId = selectedReason ? selectedReason.id : null;
		const { selection_type, selection_id} = selection;
		if(selection_type === ESelectionTypes.YES_NO_SELECTION && selection_id === SELECTION_OPTIONS[ESelectionTypes.YES_NO_SELECTION].YES) {
			switch(reasonId) {
				case REASONS_IDS.SCHEDULE_CHANGES:
					return RoutesConstants.CANCELLATION_RESCHEDULE;
				case REASONS_IDS.COUDLNT_MEET_TIME:
				case REASONS_IDS.TOO_EXPENSIVE:
				case REASONS_IDS.OUTSIDE_PULS_PLATFORM:
				case REASONS_IDS.OTHER: 
					return RoutesConstants.CANCELLATION_OFFER_ACCEPTED
				default: return;
			}
		} else return RoutesConstants.CANCELLATION_APPROVED;
	},
	[RoutesConstants.RESCHEDULE]: () => {
		return RoutesConstants.RESCHEDULE;
	},
	[RoutesConstants.CANCEL + RoutesConstants.RESCHEDULE]: () => {
		return RoutesConstants.CANCELLATION_RESCHEDULE;
	},
	[RoutesConstants.TIMES]: ({appState, selection}: any) => {
		const baseUrl = RoutesConstants.RESCHEDULE ;
		return getTimesNextPath(baseUrl, selection)
	},
	[RoutesConstants.CANCEL + RoutesConstants.TIMES]: ({appState, selection}: any) => {
		const baseUrl = RoutesConstants.CANCEL + RoutesConstants.RESCHEDULE;
		return getTimesNextPath(baseUrl, selection)
	},
	[RoutesConstants.RESCHEDULE + RoutesConstants.CONFIRM]: ({appState, selection}: any) => {
		return RoutesConstants.RESCHEDULE + RoutesConstants.CONFIRMED;	
	},
	[RoutesConstants.CANCEL + RoutesConstants.RESCHEDULE + RoutesConstants.CONFIRM]: ({appState, selection}: any) => {
		return RoutesConstants.CANCELLATION_OFFER_ACCEPTED
	}
}

function getTimesNextPath(baseUrl: string, selection: any) {
	const { selection_type, selection_id} = selection;
	if(selection_type === ESelectionTypes.YES_NO_SELECTION) { 
		if(selection_id === SELECTION_OPTIONS[ESelectionTypes.YES_NO_SELECTION].YES) {
			return baseUrl + RoutesConstants.CONFIRM;
		} else { 
			return baseUrl + RoutesConstants.TIMES
		}
	}
	return baseUrl;
}

function _matchPath(pathname: string, pattern: string | string[], opts : any = {} ) {
	const patterns = Array.isArray(pattern) ? pattern : [pattern];

	return patterns.reduce((acc: boolean, next: string) => {
		return acc || !!matchPath(pathname, { 
			path: next,
			exact: !!opts.exact,
			strict: !!opts.strict
		});
	  }, false);
	  
}