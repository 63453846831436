import actionConstants from '../Constants/actions.ts';
import _ from 'lodash';

const initialState = {
	marketingContent: [],
	blogPosts: []
};

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
	case (actionConstants.USER.UPDATE_USER_DATA):
		newState = _.cloneDeep(state);
		newState.marketingContent = action.payload.marketingContent;
		newState.blogPosts = action.payload.blogPosts;
		return newState;
	default:
	}
	return state;
};
