import actions from "../Constants/actions";

const initialState = {
    plansById: null,
    isLoadingPlans: false
}

export default (state = initialState, action: any) => {
    const { type, payload } = action;
    switch(type) {
        case actions.UTILS.UPDATE_PLANS:
            return {
                ...state,
                plansById: payload,
                isLoadingPlans: false
            }
        case actions.UTILS.SET_IS_LOADING_PLANS:
            return {
                ...state,
                isLoadingPlans: payload
            }
        default:
            return state;
    }
}