import ac from '../Images/devices/ac.png';
import electric from '../Images/devices/electric.png';
import kitchen from '../Images/devices/kitchen.png';
import plumbing from '../Images/devices/plumbing.png';
import laundry from '../Images/devices/laundry.png';

export const categories = {
    HVAC: 'Heating & Cooling',
    ELECTRICAL: 'Electrical',
    PLUMBING: 'Plumbing',
    KITCHEN_APPLIANCES: 'Kitchen Appliances',
    LAUNDRY_APPLIANCES: 'Laundry Appliances'
}

const devicesCategories = [
    {
        title: categories.HVAC,
        devices: [362, 363, 364],
        image: ac
    },
    {
        title: categories.ELECTRICAL,
        devices: [365],
        image: electric
    },
    {
        title: categories.PLUMBING,
        devices: [366, 367],
        image: plumbing
    },
    {
        title: categories.KITCHEN_APPLIANCES,
        devices: [270, 275, 274, 278, 266, 269],
        image: kitchen
    },
    {
        title: categories.LAUNDRY_APPLIANCES,
        devices: [267, 268],
        image: laundry
    }
];

export default devicesCategories;


const deviceCategoryMap = {};
devicesCategories.forEach(category => {
    category.devices.forEach(deviceId => {
        deviceCategoryMap[deviceId] = category.title;
    });
});

export { deviceCategoryMap };