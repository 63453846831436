
let config = null;

export default {
	api: () => getConfigItem('api'),
	domain: () => getConfigItem('domain'),
	googleMapsKey: () => getConfigItem('googleMapsKey'),
	webapi: () => getConfigItem('webapi'),
	googleReCaptchaKey: () => getConfigItem('googleReCaptchaKey')
};

function getConfigItem (key) {
	if (process.env.NODE_ENV === 'production') {
		if (!config) {
			var cnf = document.getElementById('config-server-data').innerText;
			config = JSON.parse(cnf);
		}
		return config[key];
	} else {
		if (!config) {
			config = {
				api: process.env.REACT_APP_API,
				domain: process.env.REACT_APP_DOMAIN,
				googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
				webapi: process.env.REACT_APP_WEB_API,
				googleReCaptchaKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
			};
		}
		if (!config[key]) throw new Error('configuration error');
		return config[key];
	}
}
