import actionConstants from '../Constants/actions.ts';
import _, { uniqBy } from 'lodash';
import field from '../Constants/field.js';
import utils from '../Services/utils';
import formatters from '../Services/formatters';
import { plans } from '@puls/puls-common-constants';
const { PLANS_IDS } = plans;

const PROTECTION_PLANS = [PLANS_IDS.PROTECTION_ESSENTIALS, PLANS_IDS.PROTECTION_STANDARD, PLANS_IDS.WHOLE_HOME, PLANS_IDS.APPLIANCES_PROTECTION, PLANS_IDS.SYSTEM_PROTECTION];
const initialState = {
	isUserAutenticated: false,
	isLoadingDevices: true,
	isLoadingUser: false,
	isLoadingMilestones: false,
	account_id: 0,
	market: {
		id: 0,
		title: ''
	},
	membership: {
		subscription_id: 0,
		plan_id: 0,
		plan_title: '',
		benefits: [],
		milestones: null,
		start_date: 0,
		end_date: 0,
		is_protection_plan: false,
		payment: {
			payment_method: '',
			card_type: '',
			last_digits: ''
		},
		next_installment: '',
		last_installment: '',
		period_type: '',
		uuid: ''

	},
	protection_devices: [],
	protection_devices_categories: {},
	has_payment_issue: false
};

_.values(field).forEach(f => {
	initialState[f] = '';
});

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
	case (actionConstants.USER.UPDATE_USER_TOKEN):
		newState = _.cloneDeep(state);
		newState.isUserAutenticated = action.payload;
		return newState;
	case (actionConstants.USER.CHNAGE_USER_DETAIL):
		newState = _.cloneDeep(state);
		newState[action.payload.field] = action.payload.value;
		return newState;
	case (actionConstants.VERIFICATION.UPDATE_AUTHENTICATION_RESULT_DATA):
		newState = _.cloneDeep(initialState);
		newState.isUserAutenticated = action.payload.token;
		return newState;
	case (actionConstants.USER.CHANGE_LOADING_USER_INDICATOR):
		newState = _.cloneDeep(state);
		newState.isLoadingUser = action.payload;
		return newState;
	case (actionConstants.USER.UPDATE_USER_DATA):
		var { account } = action.payload;
		if(!account) return state;
		newState = _.cloneDeep(state);
		newState[field.EMAIL] = account.email;
		newState[field.FIRST_NAME] = account.first_name;
		newState[field.LAST_NAME] = account.last_name;
		newState[field.PHONE] = account.phone;
		newState.account_id = account.id;
		if (account.market_id) {
			newState.market.id = account.market_id;
			newState.market.title = account.market_title;
		}
		
		if (account.membership?.is_active) {
			newState.membership.plan_id = account.membership.plan_id;
			newState.membership.title = account.membership.plan_title;
			newState.membership.benefits = account.membership.benefits;
			newState.membership.start_date = account.membership.start_date;
			newState.membership.end_date = account.membership.end_date;
			newState.membership.subscription_id = account.membership.id;
			newState[field.ADDRESS] = account.membership.full_address;
			newState.membership.is_protection_plan = (account.membership.plan_type_id === 2 || account.membership.plan_type_id === 3);
			newState.membership.uuid = account.membership.uuid;
			newState.membership.next_installment = account.membership.next_installment || '';
			newState.membership.last_installment = account.membership.last_installment || '';
		}

		if(!newState[field.ADDRESS]) { 
			try {
				newState[field.ADDRESS] = formatters.address(account.address)
			} catch (error) {
				
			}

			if(!newState[field.ADDRESS]) {
				try {
					newState[field.ADDRESS] = account.membership.full_address
				} catch (error) {
					
				}
			}
		}
		newState.isLoadingUser = false;
		return newState
	case (actionConstants.USER.LOAD_PROTECTION_DEVICES):
		let addOnsDevices = [];
		let devices = [];
		action.payload.forEach((device) => {
			device.is_addon ? addOnsDevices.push(device) : devices.push(device);
		})
		devices = uniqBy(devices, 'device_id');
		newState = _.cloneDeep(state);
		newState.protection_devices = [...devices, ...addOnsDevices];
		newState.protection_devices_categories = utils.buildProtectionDevicesByCategory({devices, addOnsDevices});
		newState.isLoadingDevices = false;
		return newState;
	case (actionConstants.USER.UPDATE_PAYMENT_METHOD_DETAILS):
		newState = _.cloneDeep(state);
		newState.has_payment_issue = action.payload.has_payment_issue;
		newState.membership.payment.card_type = action.payload.card_type || '';
		newState.membership.payment.last_digits = action.payload.card_last_four_digits || '';
		newState.membership.payment.payment_method = action.payload.paymentMethod || '';
		return newState;
	case (actionConstants.USER.UPDATE_MILESTONES):
		newState = _.cloneDeep(state);
		newState.membership.milestones = action.payload;
		newState.isLoadingMilestones = false;
		return newState;
	case (actionConstants.USER.SET_IS_LOADING_MILESTONES):
		newState = _.cloneDeep(state);
		newState.isLoadingMilestones = action.payload;
		return newState;
	default:
	}
	return state;
};
