export interface Device {
    device_title: string,
    device_id: number,
    subscription_device_id: number,
    is_claimable: boolean,
    coverage: DeviceCoverage,
    ineligibility_reason: string
}

export enum EDevicesIds {
    FRIDGE = 269,
    DISHWASHER = 266,
    MICROWAVE = 275,
    OVEN_COOKTOP = 270,
    WASHER = 267,
    DRYER = 268
}

interface DeviceCoverage {
    current: number,
    max: number
}