class History{
    _history: any = null;

    init(his: any){
        if(this._history !== null) return;
        this._history = his;
    }

    get history() {
        if(!this._history) throw new Error('History is null');
        return this._history;
    }
}

export default new History()

