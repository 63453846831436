import RoutesConstants from 'src/Constants/routes.js'

export const REASONS_IDS = {
    TOO_EXPENSIVE: 89,
    SCHEDULE_CHANGES: 82,
    COUDLNT_MEET_TIME: 99,
    ISSUE_RESOLVED: 90,
    ANOTHER_COMPANY: 20,
    OUTSIDE_PULS_PLATFORM: 101,
    OTHER: 98
}

export enum ESelectionTypes {
    YES_NO_SELECTION
}

export enum EYesNoSelection { 
    NO, 
    YES
}

export const SELECTION_OPTIONS = {
    [ESelectionTypes.YES_NO_SELECTION]: EYesNoSelection
}


const patterns = {
    CANCEL: RoutesConstants.APPOINTMENT_DETAILS + RoutesConstants.CANCEL,
	CONFIRM_CANCELLATION: RoutesConstants.APPOINTMENT_DETAILS + RoutesConstants.CONFIRM_CANCELLATION,
	CANCELLATION_RESCHEDULE: RoutesConstants.APPOINTMENT_DETAILS + RoutesConstants.CANCELLATION_RESCHEDULE,
	REGULER_RESCHEDULE: RoutesConstants.APPOINTMENT_DETAILS + RoutesConstants.RESCHEDULE,
}

export const routePatterns = {
    ...patterns, 
    RESCHEDULE: [patterns.REGULER_RESCHEDULE],
    CANCEL_RESCHEDULE: [patterns.CANCELLATION_RESCHEDULE],
    RESCHEDULE_TIMES: [patterns.REGULER_RESCHEDULE + RoutesConstants.TIMES, patterns.REGULER_RESCHEDULE + RoutesConstants.STF],
    CANCEL_RESCHEDULE_TIMES: [patterns.CANCELLATION_RESCHEDULE + RoutesConstants.TIMES, patterns.CANCELLATION_RESCHEDULE + RoutesConstants.STF],
    RESCHEDULE_CONFIRM: [patterns.REGULER_RESCHEDULE + RoutesConstants.CONFIRM],
    CANCEL_RESCHEDULE_CONFIRM: [patterns.CANCELLATION_RESCHEDULE + RoutesConstants.CONFIRM]
}
