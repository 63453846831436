export default {
	CONFIG: {
		UPDATE_IFRAME_LINK: 'UPDATE_IFRAME_LINK',
		UPDATE_APP_MODE: 'UPDATE_APP_MODE',
		UPDATE_RETURN_FROM_SALE_FLAG: 'UPDATE_RETURN_FROM_SALE_FLAG',
		TOGGLE_MENU_MODE: 'TOGGLE_MENU_MODE',
		SET_ERROR: 'SET_ERROR'
	},
	USER: {
		UPDATE_USER_AUTHENTICITY: 'UPDATE_USER_AUTHENTICITY',
		UPDATE_USER_TOKEN: 'UPDATE_USER_TOKEN',
		AUTHENTICATE_USER: 'AUTHENTICATE_USER',
		CHNAGE_USER_DETAIL: 'CHNAGE_USER_DETAIL',
		CHANGE_LOADING_USER_INDICATOR: 'CHANGE_LOADING_USER_INDICATOR',
		UPDATE_USER_DATA: 'UPDATE_USER_DATA',
		LOAD_PROTECTION_DEVICES: 'LOAD_PROTECTION_DEVICES',
		UPDATE_PAYMENT_METHOD_DETAILS: 'UPDATE_PAYMENT_METHOD_DETAILS',
		UPDATE_MILESTONES: 'UPDATE_MILESTONES',
		SET_IS_LOADING_MILESTONES: 'SET_IS_LOADING_MILESTONES'
	},
	VERIFICATION: {
		CHNAGE_SEND_EMAIL_VERIFICATION_INDICATOR: 'CHNAGE_SEND_EMAIL_VERIFICATION_INDICATOR',
		UPDATE_VERIFICATION_DATA: 'UPDATE_VERIFICATION_DATA',
		CHNAGE_VERIFICATION_CODE_NUMBER: 'CHNAGE_VERIFICATION_CODE_NUMBER',
		UPDATE_VERIFICATION_INDICATOR: 'UPDATE_VERIFICATION_INDICATOR',
		UPDATE_AUTHENTICATION_RESULT_DATA: 'UPDATE_AUTHENTICATION_RESULT_DATA'
	},
	SNACKBAR: {
		SHOW_SNACKBAR: 'SHOW_SNACK_BAR',
		HIDE_SNACKBAR: 'HIDE_SNACKBAR'
	},
	APPOINTMENTS: {
		GET_APPOINTMENT_DETAILS: 'GET_APPOINTMENT_DETAILS',
		UPDATE_LOADING_APPOINTMENT_DETAILS: 'UPDATE_LOADING_APPOINTMENT_DETAILS',
		UPDATE_SENDING_INVOICE_INDICATOR: 'UPDATE_SENDING_INVOICE_INDICATOR',
		UPDATE_LOADING_APPOINTMENTS: 'UPDATE_LOADING_APPOINTMENTS'
	},
	CLAIMS: {
		INSERT_BLOCK_OBJECT: 'INSERT_BLOCK_OBJECT',
		UPDATE_SELECT_ROW: 'UPDATE_SELECT_ROW',
		UPDATE_CLAIMABLE: 'UPDATE_CLAIMABLE',
		UPDATE_CLAIMABLE_DEVICE: 'UPDATE_CLAIMABLE_DEVICE',
		START_OVER_CLAIM: 'START_OVER_CLAIM',
		CLEAR_CLAIM_CHAT: 'CLEAR_CLAIM_CHAT',
		EDIT_SELECTION: 'EDIT_SELECTION',
		CLEAR_TO_INDEX: 'CLEAR_TO_INDEX',
		END_CLAIM_CHAT: 'END_CLAIM_CHAT',
		CREATE_CLAIM: 'CREATE_CLAIM',
		CREATE_CLAIM_SUCCESS: 'CREATE_CLAIM_SUCCESS',
		CREATE_CLAIM_FAIL: 'CREATE_CLAIM_FAIL',
		UPDATE_ADMIN_DATA: 'UPDATE_ADMIN_DATA',
		UPDATED_MESSAGES: 'UPDATED_MESSAGES',
		SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
		SET_IS_LOADING: 'SET_IS_LOADING',
		SET_CLAIM_UUID: 'SET_CLAIM_UUID'
	},
	RESCHEDULE: {
		UPDATE_RESCHEDULE_APPOINTMENT: 'UPDATE_RESCHEDULE_APPOINTMENT',
		SET_SUBMIT_RESCHEDULE: 'SET_SUBMIT_RESCHEDULE',
		SET_RESCHEDULE_VALIDATION: 'SET_RESCHEDULE_VALIDATION',
		SET_RESCHEDULE_APPOINTMENT_UUID: 'SET_RESCHEDULE_APPOINTMENT_UUID'
	},
	CANCEL: {
		SET_LOADING_REASONS: 'SET_LOADING_REASONS',
		UPDATE_REASONS: 'UPDATE_REASONS',
		SET_SELECTED_REASON: 'SET_SELECTED_REASON',
		SET_CANCELING_APPOINTMENT: 'SET_CANCELING_APPOINTMENT',
		UPDATE_CANCELATION_STATUS: 'UPDATE_CANCELATION_STATUS',
		UPDATED_MESSAGES: 'UPDATED_MESSAGES',
		SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
		SET_LOADING_ELIGIBILITY: 'SET_LOADING_ELIGIBILITY',
		SET_ELIGIBILITY_DATA: 'SET_ELIGIBILITY_DATA',
		SET_USER_FREE_TEXT: 'SET_USER_FREE_TEXT',
		CLEAR_CANCELLATION_DATA: 'CLEAR_CANCELLATION_DATA',
		SET_FINISHED_FLOW: 'SET_FINISHED_FLOW'
	},
	UTILS: {
		UPDATE_PLANS: 'UPDATE_PLANS',
		SET_IS_LOADING_PLANS: 'SET_IS_LOADING_PLANS'
	}
};
